import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthJwtService } from '../../services/auth-jwt.service';
import Swal from 'sweetalert2';

@Component({
  templateUrl: './conditions-accept.component.html'
})
export class ConditionsAcceptComponent implements OnInit {

  acceptCrm = false;
  acceptCrmR1 = false;

  confirmText: string = '';
  confirmButtonText: string = '';
  cancelButtonText: string = '';

  constructor(
    private userService: UserService, private router: Router, private authJwtService: AuthJwtService) { }

  ngOnInit(): void {
  }


  setConditions() {

    this.setConfirmTexts();

    if(!this.acceptCrm || !this.acceptCrmR1)
    {
      Swal.fire({
        title: 'Importante',
        html: this.confirmText,
        icon: 'warning',
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-info w-90 mb-2',
          confirmButton: 'btn btn-outline-secondary btn-sm w-90'
        },
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: this.confirmButtonText,
        cancelButtonText: this.cancelButtonText,
        cancelButtonColor: '#d33',
        focusCancel: true,
        reverseButtons: true,
        backdrop: false
      }).then((result) => {
        if (!result.value) { // Acepta recibir comunicaciones
          this.acceptCrm = true;
          this.acceptCrmR1 = true;
        }
        setTimeout(() => {
          this.setConditionsCall();
        }, 300);
      })
    }
    else{
      this.setConditionsCall();
    }

  }

  setConditionsCall(){
    this.userService.SetConditionsDistributor(this.acceptCrm, this.acceptCrmR1).subscribe(() => {
      var refreshToken = this.authJwtService.getRefreshToken();
      var token = this.authJwtService.getToken();
      let body = { "Token": token, "RefreshToken": refreshToken };
      this.authJwtService.refreshToken(body).subscribe(()=>{
        this.router.navigate(['/home']);
      });
    });
  }

  setConfirmTexts() {
    this.confirmText = '';
    this.confirmButtonText = '';
    this.cancelButtonText = '';

    if(!this.acceptCrm) {
      this.confirmText = '<p class="lead">No has aceptado recibir información acerca de nuestras promociones.</p>';
      this.confirmButtonText = 'NO ACEPTAR COMUNICACIONES';
      this.cancelButtonText = 'ACEPTAR COMUNICACIONES Y CONTINUAR';
    }

    if(!this.acceptCrmR1) {
      this.confirmText = '<p class="lead">No has aceptado ceder tus datos a tu concesión adherida.</p>';
      this.confirmButtonText = 'NO CEDER MIS DATOS';
      this.cancelButtonText = 'ACEPTAR CEDER MIS DATOS';
    }

    if(!this.acceptCrm && !this.acceptCrmR1) {
      this.confirmText = '<p class="lead">No has aceptado recibir información acerca de nuestras promociones, ni ceder tus datos a tu concesión adherida.</p>';
      this.confirmButtonText = 'NO ACEPTAR COMUNICACIONES NI CEDER MIS DATOS';
      this.cancelButtonText = 'ACEPTAR COMUNICACIONES Y CEDER MIS DATOS Y CONTINUAR';
    }
  }

  cancel() {
    this.authJwtService.logout();
    this.router.navigate(['/home']);
  }

}
