

import { Component, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { CatalogService } from '../../../services/catalog.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Helpers } from '../../../helpers/helpers';
import Swal from 'sweetalert2';
import { LanguageApp } from '../../../config/datatable-config';
import { CatalogOrderDatatableFilter } from '../../../models/puntoapunto-catalog-item-dto';
import { Select2Data } from 'ng-select2-component';
import { PuntoapuntoUserDTO } from '../../../models/puntoapunto-user-dto';
import { UserService } from '../../../services/user.service';
import { Status } from '../../../enums/status';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../../services/shared.service';
import { UserAnimationPointService } from '../../../services/user-animation-point.service';
import { DistributorParams } from '../../../models/distributor-params';
import { DecimalPipe } from '@angular/common';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-catalog-item-order-table',
  templateUrl: './catalog-item-order-table.component.html',
})

export class CatalogItemOrderTableComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  listenerFn!: () => void;

  filter: CatalogOrderDatatableFilter = new CatalogOrderDatatableFilter();
  yearsData: Select2Data;
  totalPoints;
  userInfo: PuntoapuntoUserDTO;
  IdCatalogItemOrderColumn: number;
  columns: any = [];
  statusData: Select2Data;
  catalogItemData: Select2Data;
  userData: Select2Data;
  @Input() miArea = true;
  @Input() DistributorParams: DistributorParams = null;
  @Output() onCancelOrder = new EventEmitter<boolean>();
  isAdmin = false;
  isAdminCssClass = '';

  yearSelected: number = new Date().getFullYear();
  statusSelected: number = null;
  productSelected: number = null;

  constructor(private service: CatalogService,
    private userService: UserService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private renderer: Renderer2,
    private helper: Helpers,
    private userPointService: UserAnimationPointService,
    private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.filter.DistributorParams = this.DistributorParams;
    this.loadData();
    this.getUserInfo();
    this.setDatatable();

    this.listenerFn = this.renderer.listen('document', 'click', (event) => {
      if (event.target.closest('tr')) {
        if (event.target.closest('tr').hasAttribute("data-row-id")) {
          const id = event.target.closest('tr').getAttribute("data-row-id");
          var elements = document.querySelectorAll('[data-span-id="' + id + '"]');
          if (elements.length > 1) {
            elements[1].innerHTML = elements[0].innerHTML;
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {

    this.dtTrigger.next(null);
    this.listenerFn = this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute("cancel-order-id")) {
        this.delete(event.target.getAttribute("cancel-order-id"));
      }
    });
    this.helper.setDataTableSearchDelay();

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this.listenerFn) {
      this.listenerFn();
    }
  }

  rerender(): void {
    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.draw();
      });
    }
  }

  setDatatable() {
    const that = this;
    if (!this.isAdmin) {
      this.isAdminCssClass = 'd-none';
    }
    this.dtOptions = {
      responsive: true,
      paging: true,
      pageLength: 25,
      ordering: false,
      lengthChange: true,
      searching: false,
      info: false,
      serverSide: true,
      processing: true,
      language: LanguageApp.spanish_datatables,
      ajax: (dataTablesParameters: any, callback) => {
        that.service.getCatalogItemOrderDatatable(dataTablesParameters, JSON.stringify([this.filter])).subscribe(resp => {
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: this.CustomData(resp.data)
          });
        }, (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error al recuperar los datos',
            text: 'Ocurrió un error'
          });

        });
      },
      drawCallback: function () {
        that.helper.enableBsTooltips();
        that.appendDownloadButton();
      },
      columns: that.getColumns(),
      columnDefs: [
        {
          targets: [0],
          data: "ImageFile.FileUri",
          className: "align-middle text-center",
          render: function (data, type, full) {
            var retorno = "";
            retorno = '<img class="image-table" src="' + data + '" />';
            return retorno;
          }
        },
        {
          targets: [4],
          data: "Status",
          className: "align-middle text-center",
          render: function (data, type, full) {
            let cssClasses = 'order-status ';
            if (full.Status === 'ENVIADO') {
              cssClasses += 'sent';
            } else if (full.Status === 'PENDIENTE' || full.Status === 'EN PROCESO') {
              cssClasses += 'pending';
            } else if (full.Status === 'CANCELADO') {
              cssClasses += 'canceled';
            }
            const retorno = '<div class="' + cssClasses + '">' + data + '</div>';
            return retorno;
          }
        },
        {
          targets: [this.IdCatalogItemOrderColumn],
          data: "IdCatalogItemOrder",
          className: "align-middle text-center text-nowrap",
          render: function (data, type, full) {
            var retorno = "";
            if (full.Status.toLowerCase() == 'pendiente')
              retorno += '<button cancel-order-id="' + data + '" class="btn btn-sm btn-secondary-action" title="Cancelar pedido"><i class="bi bi-x-lg"></i> Cancelar pedido</button>';

            // <button (click)="delete()" type="button" class="btn btn-secondary-action"><i class="bi bi-x-lg"></i> CANCELAR PEDIDO</button>
            return retorno;
          }
        },
        {
          targets: [6],
          data: "Units",
          className: "align-middle",
          render: function (data, type, full) {
            var retorno = "";
            retorno = '<div class="table-points">' + that.decimalPipe.transform(data, '1.0-0') + ' </div>';
            return retorno;
          }
        },
        {
          targets: [7],
          data: "Points",
          className: "align-middle",
          render: function (data, type, full) {
            var retorno = "";
            retorno = '<div class="table-points">' + that.decimalPipe.transform(data, '1.0-0') + ' </div>';
            return retorno;
          }
        },
        {
          targets: [8],
          data: "IdCatalogItemOrderInt",
          className: this.isAdminCssClass,
          render: (data) => {
            var retorno = "";
            retorno += "<span data-span-id='txtCount_" + data + "'></span>"
            return retorno;
          }
        },
      ]
    };
  }

  search() {
    this.rerender();
    this.GetTotalPointsOrders();
  }

  GetTotalPointsOrders() {
    this.service.GetTotalPointsOrders(JSON.stringify([this.filter])).subscribe(res => {
      this.totalPoints = res;
    })
  }

  getUserInfo() {
    this.userService.getUserData().subscribe(res => {
      if (res) {
        this.userInfo = res;
        this.filter.Year = new Date().getFullYear();

        if (this.miArea) {
          this.filter.IdUser = this.userInfo.IdUser
        }
        this.isAdmin = this.userInfo.RolesListString.includes("Administrador");
        this.GetTotalPointsOrders();
        this.rerender();
      }
    });

  }

  getColumns() {

    if (this.miArea) {

      this.columns =
        [
          { data: "ImageFile.FileUri" },
          { data: "OrderDate", className: "text-center" },
          { data: "Title", className: "text-start" },
          { data: "IdCatalogItemOrderInt", className: "text-start" },
          { data: "Status", className: "text-start" },
          { data: "IdCatalogItemOrder" },
          { data: "Units", className: "text-center" },
          { data: "Points", className: "text-center" },
          { data: "IdCatalogItemOrderInt", className: "text-center" }
        ]

      this.IdCatalogItemOrderColumn = 5;

    } else {

      this.columns =
        [
          { data: "ImageFile.FileUri" },
          { data: "OrderDate", className: "text-center" },
          { data: "Title", className: "text-start" },
          { data: "IdCatalogItemOrderInt", className: "text-start" },
          { data: "Status", className: "text-start" },
          { data: "User" },
          { data: "Units", className: "text-center" },
          { data: "Points", className: "text-center" },
          { data: "IdCatalogItemOrderInt", className: "text-center" }
        ]
    }

    return this.columns

  }

  delete(idOrder) {
    Swal.fire({
      title: '¿Seguro que quieres cancelar el pedido?',
      text: "El pedido se cancelará",
      icon: 'warning',
      buttonsStyling: false,
      customClass: {
        cancelButton: 'btn-dimgrey me-2',
        confirmButton: 'btn btn-outline-secondary'
      },
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: '<i class="fa fa-trash-o" aria-hidden="true"></i> Cancelar pedido',
      cancelButtonText: '<i class="bi bi-x-lg"></i> Cerrar',
      cancelButtonColor: '#d33',
      reverseButtons: true,
      focusCancel: true
    }).then((result) => {
      if (result.value) {
        this.CancelOrder(idOrder);
      }
    })
  }

  CancelOrder(idOrder) {

    this.service.CancelOrder(idOrder, Status.Cancelado).subscribe(res => {

      this.toastr.success("El registro se ha creado correctamente");
      this.rerender();
      this.GetTotalPointsOrders();
      this.userPointService.ReloadUserAnimationPoints();
      this.onCancelOrder.emit(true);

    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al cancelar el pedido',
        text: 'Ocurrió un error'
      });

    });
  }

  loadData() {
    this.loadYears();
    if (!this.miArea) {
      this.loadStatus();
      this.loadCatalogItem();
      //this.loadUser();
    }

  }

  loadYears() {
    this.service.GetOrdersYears().subscribe(res => {
      this.yearsData = res;
      this.filter.Year = +new Date().getFullYear().toString();
    })
  }

  loadStatus() {

    this.sharedService.getPuntoapuntoCustomStatus([Status.Pendiente, Status.Enviado, Status.Cancelado]).subscribe(resp => {

      resp.unshift({ value: null, label: '-Todos los estados-' });
      this.statusData = resp;
      this.filter.IdStatus = null;

    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al recuperar los datos',
        text: 'Ocurrió un error'
      });
    });

  }

  loadCatalogItem() {

    this.service.GetPuntoapuntoCatalogItemSelector().subscribe(resp => {

      resp.unshift({ value: null, label: '-Todos los productos-' });
      this.catalogItemData = resp;
      this.filter.IdCatalogItem = null;

    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al recuperar los datos',
        text: 'Ocurrió un error'
      });
    });

  }

  updateYear() {
    if (this.yearSelected != this.filter.Year) {
      this.filter.Year = this.yearSelected;
      this.search();
    }
  }

  updateStatus() {
    if (this.statusSelected != this.filter.IdStatus) {
      this.filter.IdStatus = this.statusSelected;
      this.search();
    }
  }

  updateProduct() {
    if (this.productSelected != this.filter.IdCatalogItem) {
      this.filter.IdCatalogItem = this.productSelected;
      this.search();
    }
  }

  downloadExcel() {

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });

    Swal.showLoading();

    this.service.getCatalogOrderExcel(this.yearSelected).subscribe(blob => {
      Swal.close();
      saveAs(blob, "PAP_PedidosCatalogo_" + this.yearSelected + ".xlsx");
    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al acceder a la BD',
        text: 'Ocurrió un error'
      });
    });
  }

  appendDownloadButton() {
    const downloadButton = document.getElementById("order-excel-bt");
    const tableRow = document.querySelector("#tblnews_wrapper>div");
    const tableCol = document.querySelector("#tblnews_wrapper>div>div:nth-child(2)");
    tableCol.prepend(downloadButton);
    tableRow.classList.add("align-items-center");
    tableCol.classList.add("text-end");
    downloadButton.classList.remove("d-none");
  }

  CustomData(obj: any[]) {
    if (this.isAdmin) {
      const that = this;
      obj.forEach(function (dataB) {
        that.userPointService.getUserAnimationPointsByIdTresor(dataB.IdTresor).subscribe((resp) => {
          var id = "txtCount_" + dataB.IdCatalogItemOrderInt;
          var element = document.querySelector('[data-span-id="' + id + '"]');
          if (element != null) {
            if (resp != null) {
              element.innerHTML = resp.TotalPoints.toString();
            } else {
              element.innerHTML = "0";
            }
            element.closest('tr').setAttribute('data-row-id', id);
          }
        });
      });
    }

    return obj;
  }

}


