<!-- PREFOOTER -->
<div *ngIf="modulesSet == 'home' || modulesSet == 'login'" class="pt-sm-5">
  <div class="cta-box cta-box-dark cta-box-wide">
    <div class="cta-box-container">
      <div class="cta-box-overlay-container">
        <div class="cta-box-content">
          <div class="cta-pretitle">
            Punto de ayuda
          </div>
          <div class="cta-description">
            <h4>Cuenta con nuestra ayuda</h4>
            <p class="lead"><strong>Escríbenos:</strong></p>
            <p>Si quieres enviar una consulta, informarte de cómo hacerte socio o de las ventajas del programa, puedes hacerlo desde aquí:</p>
          </div>
          <div class="cta-action"><a class="btn-dimgrey btn-lg stretched-link pointer" data-trigger="help">ENVIAR CONSULTA</a>
          </div>
          <div class="cta-description mt-4">
            <p><strong>Nos pondremos en contacto en un plazo aproximado de 48-72 h.</strong></p>
          </div>
        </div>
      </div>
      <div class="cta-box-img-container">
        <img alt="Regalos" class="cta-box-img" src="assets/images/home/home-cta-ayuda-min.png">
      </div>
    </div>
  </div>
</div>


<div *ngIf="(modulesSet == 'home' || modulesSet == 'help') && !UserAuthenticated" class="container pt-5">
  <div class="cta-box cta-box-centered cta-box-dark cta-join">
    <div class="cta-box-container">
      <div class="cta-box-content">
        <div class="cta-description">
          <h4>
            <span class="heading-regular">
              ¿Todavía no eres socio del programa
              <img src="/assets/images/logotipo-punto-a-punto-blanco.svg" alt="Punto a punto" class="logo-header">?
            </span><br>
            Únete ya
          </h4>
        </div>
        <div class="cta-action">
          <a class="btn-blue btn-lg stretched-link" [routerLink]="['/login']">QUIERO REGISTRARME</a>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="modulesSet == 'inner'">
  <div class="highligths-cards-module pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-3 mb-lg-0">
          <div class="card">
            <img src="assets/images/home/prefooter-hl-01.png" class="card-img-top bg-darkgrey" alt="Ayuda">
            <div class="card-body bg-black">
              <p class="card-title">Cuenta con nuestra ayuda.</p>
              <p>Te informamos de cómo hacerte socio y de las ventajas del programa. Nos pondremos en contacto en un plazo aproximado de 48-72 h.</p>
              <a data-trigger="help" class="pointer" class="btn-right-arrow stretched-link">
                Enviar consulta
                <div class="svg-icon">
                  <svg viewBox="0 0 150 150">
                    <use xlink:href="assets/images/icons/arrow-right.svg#img" href="assets/images/icons/arrow-right.svg#img"></use>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <img src="assets/images/home/prefooter-hl-02.png" class="card-img-top bg-darkgrey" alt="Sumar puntos">
            <div class="card-body bg-info">
              <p class="card-title">
                <span class="heading-regular">Tus compras en Punto a Punto,</span>
                ¡suman!
              </p>
              <a *ngIf="!UserAuthenticated" [routerLink]="['/login']" class="btn-right-arrow stretched-link">
                Regístrate
                <div class="svg-icon">
                  <svg viewBox="0 0 150 150">
                    <use xlink:href="assets/images/icons/arrow-right.svg#img" href="assets/images/icons/arrow-right.svg#img"></use>
                  </svg>
                </div>
              </a>
              <a *ngIf="UserAuthenticated && animatedFamiliesLink" [href]="[animatedFamiliesLink]" class="btn-right-arrow stretched-link">
                Familias animadas
                <div class="svg-icon">
                  <svg viewBox="0 0 150 150">
                    <use xlink:href="assets/images/icons/arrow-right.svg#img" href="assets/images/icons/arrow-right.svg#img"></use>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="modulesSet != 'void'" class="spacer pt-5"></div>